import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, List, Tooltip } from 'antd';
import { CopyOutlined, LoadingOutlined, QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { AMCActions, DepositActions, PMCActions } from 'src/Redux';
import { ContentLoader, ContentNoData, Notification, Number } from 'src/Components';
import { accountsColorObject, checkJsonProp, currencyTextValue, CustomIcon, i18, logError, translationGroups, trb } from 'src/Utilities';
import { routerPath } from 'src/Config';
import { ModalDepositTermination } from './';

export const AccountsContainer = ({ hideTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ALR, sendALR, sendADR } = useSelector((state) => state.AMCReducers);
  const { sendDeposit, depositTerminationData, depositTerminationRequest, depositTerminationConfirm } = useSelector((state) => state.DepositReducers);

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    loadAllData();
  }, []);

  const loadAllData = () => {
    dispatch(AMCActions.getALR());
  };

  const accountClick = (item) => {
    navigate(routerPath.invoice, {
      state: {
        accountId: item.AccountID,
      },
    });
  };

  const postDepositTerminationConfirm = () => {
    dispatch(DepositActions.postDepositTerminationConfirm(depositTerminationRequest));
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const cancelApprove = () => {
    hideModal();
    dispatch(DepositActions.cancelDepositTerminationConfirm());
  };

  const successApprove = async () => {
    Notification({
      type: 'success',
      message: i18.NotificationTitles.DepositTerminationSuccess,
      description: i18.NotificationMessages.DepositTerminationSuccess,
    });
    hideModal();
    loadAllData();
  };

  const submitSmsApprove = async (values) => {
    try {
      const response = await dispatch(PMCActions.postAPESCR(values));
      if (response?.payload?.IsSuccessfull) {
        successApprove();
      } else {
        cancelApprove();
      }
    } catch (error) {
      logError(error);
    }
  };

  const submitMobileApprove = async () => {
    const { RQ_ID } = depositTerminationConfirm,
      response = await dispatch(PMCActions.postAPESCR({ RQ_ID: RQ_ID }));

    if (checkJsonProp(response.payload, 'AuthCheckResult') === null) {
      return true;
    }
    if (checkJsonProp(response.payload, 'AuthCheckResult') === false) {
      cancelApprove();
    } else if (checkJsonProp(response.payload, 'AuthCheckResult') === true && checkJsonProp(response.payload, 'IsSuccessfull')) {
      successApprove();
    }
    return false;
  };

  const addOverdraftInfo = (overdraftItem, indx) => {
    return overdraftItem ? (
      <div className="d-none d-md-inline text-wrap">
        <div className="text-black eb_fs_12">{trb(i18.Labels.OverdraftAgreementNumber)}</div>
        <div className="text-gray-basic eb_fs_12 eb_fs_xxl_14">
          {`${overdraftItem.AgreementNumber} `}
          <span
            id={`AccountsItemAgreementNrCopy${indx}`}
            className="copy-iban"
            style={{ margin: '0px 5px', position: 'relative', top: '1px' }}
            onClick={() => {
              navigator.clipboard.writeText(overdraftItem.AgreementNumber);
              Notification({
                type: 'success',
                message: i18.NotificationTitles.Copied,
              });
            }}
          >
            <CopyOutlined />
          </span>
        </div>
      </div>
    ) : null;
  };

  const renderItem = (item, indx) => {
    const title = trb(translationGroups.AccountTypeCode + item.AccountTypeCode);

    const accNr = item.AccountTypeCode === '4' ? item.AgreementNumber : item.AccountNumber.replace(/ /gi, '');
    const accNrText = item.AccountTypeCode === '4' ? item.AgreementNumber : item.AccountNumber;
    const overdraftItem = ALR?.Accounts?.find((itm) => itm.CurrentAccountIban === accNr);
    const amount = item.Amount;
    const currency = item.AccountCurrency;
    const creditLimit0 = overdraftItem?.CreditLimit?.[0];
    const reserved0 = item?.Reserved?.[0];
    const blockedAmount0 = item?.BlockedAmount?.[0];
    const disposableBalance0 = item?.DisposableBalance?.[0];

    const { AccountTypeCode, RBFType } = item;

    return (
      <List.Item
        id={`AccountsItem${indx}`}
        className="show-hover-fired cursor-pointer"
      >
        <div
          id={`AccountsItemAccountClick${indx}`}
          className="account-list-bg"
          onClick={() => accountClick(item)}
        />
        <List.Item.Meta
          avatar={
            <Avatar
              size={27}
              style={{
                backgroundColor: accountsColorObject?.[AccountTypeCode],
              }}
            />
          }
          title={
            <>
              <span className="eb_fs_24 text-black d-md-none">{currencyTextValue(amount, currency)}</span>
              <span className="text-black eb_fs_12 d-none d-md-inline">{RBFType ? trb(translationGroups.AccountTypeCode + RBFType) : title}</span>
              {RBFType ? (
                <Tooltip
                  placement="top"
                  title={trb(translationGroups.Tooltips + RBFType)}
                >
                  <QuestionCircleOutlined style={{ margin: '0px 5px', position: 'relative', top: '-1px' }} />
                </Tooltip>
              ) : null}
            </>
          }
          description={
            <>
              <span className="text-gray-basic eb_fs_12 eb_fs_xxl_14">
                {`${accNrText} `}
                <span
                  className="copy-iban"
                  style={{ margin: '0px 5px', position: 'relative', top: '1px' }}
                  id={`AccountsItemAccountNrCopy${indx}`}
                  onClick={() => {
                    navigator.clipboard.writeText(accNr);
                    Notification({
                      type: 'success',
                      message: i18.NotificationTitles.Copied,
                    });
                  }}
                >
                  <CopyOutlined />
                </span>
                {addOverdraftInfo(overdraftItem, indx)}
              </span>

              <span className="text-gray-basic eb_fs_12 eb_fs_xxl_14"></span>
            </>
          }
        />
        <div className="text-left align-items-center d-none d-md-flex flex-grow-2">
          <div className="d-flex hover-info flex-grow-2 justify-content-center">
            <div className="eb_fs_10 eb_fs_xxl_12 show-hover pr-1">
              {trb(i18.Labels.AccountsReserved)}
              <br />
              <span className="eb_fs_12 eb_fs_xxl_14 eb_fw_400">{currencyTextValue(reserved0?.Value ?? 0, reserved0?.Currency ?? '')}</span>
            </div>
            <div className="eb_fs_10 eb_fs_xxl_12 show-hover pr-1">
              {trb(i18.Labels.AccountsBlocked)}
              <br />
              <span className="eb_fs_12 eb_fs_xxl_14 eb_fw_400">{currencyTextValue(blockedAmount0?.Value ?? 0, blockedAmount0?.Currency ?? '')}</span>
            </div>
            <div className="eb_fs_10 eb_fs_xxl_12 show-hover pr-1">
              {trb(i18.Labels.AccountsAmount)}
              <br />
              <span className="eb_fs_12 eb_fs_xxl_14 eb_fw_400">{currencyTextValue(amount ?? 0, currency ?? '')}</span>
            </div>
            {overdraftItem ? (
              <div className="eb_fs_10 eb_fs_xxl_12 show-hover pr-1">
                {trb(i18.Labels.AccountBalanceOD)}
                <br />
                <span className="eb_fs_12 eb_fs_xxl_14 eb_fw_400">{currencyTextValue(creditLimit0?.Value ?? 0, overdraftItem.AccountCurrency)}</span>
              </div>
            ) : null}
          </div>
          <div className="eb_fs_10 eb_fs_xl_12 text-right text-gray-basic pl-1 ml-auto">
            {trb(i18.Labels.AccountsAvailable)}
            <br />
            <span className="eb_fs_12 eb_fs_xxl_14 eb_fw_400 text-black">
              <Number value={disposableBalance0?.Value} /> {disposableBalance0?.Currency}
            </span>
          </div>
          {!sendADR && !sendALR && (
            <RightOutlined
              id={`AccountsItemAccountClickButton${indx}`}
              className="ml-4"
              onClick={() => accountClick(item)}
            />
          )}
          {(sendADR || sendALR) && <LoadingOutlined />}
        </div>
        <CustomIcon
          type="custom"
          icon="caretRight"
          className="ml-4 d-md-none"
        />
      </List.Item>
    );
  };

  if (sendALR) {
    return <ContentLoader />;
  }

  if (!ALR?.Accounts?.length) {
    return <ContentNoData id="ALRAccounts" />;
  }

  const sortAccountsArray = [1, 11, 24, 23, 132, 131, 4, 3, 5, 6, 22];
  ALR.Accounts.sort((a, b) => sortAccountsArray.indexOf(a.AccountTypeCode) - sortAccountsArray.indexOf(b.AccountTypeCode));

  const filterAccounts = (accounts) => {
    const ignoredAccountTypes = ['133'];
    return accounts.filter((itm) => !ignoredAccountTypes.includes(itm.AccountTypeCode));
  };
  return (
    <>
      <div className="eb_content p-0">
        {!hideTitle && <div className="eb_content_title">{trb(i18.Container.AccountsContainer)}</div>}
        <List
          id="AccountsList"
          itemLayout="horizontal"
          dataSource={filterAccounts(ALR.Accounts)}
          renderItem={renderItem}
          className="eb_accounts_panel_scroll"
        />
      </div>
      {(modalVisible || sendDeposit) && (
        <ModalDepositTermination
          hideModal={hideModal}
          modalVisible={true}
          submit={postDepositTerminationConfirm}
          initialValues={depositTerminationData}
          sendDeposit={sendDeposit}
          approve={!!depositTerminationConfirm}
          RQ_ID={depositTerminationConfirm?.RQ_ID}
          AuthType={depositTerminationConfirm?.AuthType}
          ControlCode={depositTerminationConfirm?.ControlCode}
          cancelApprove={cancelApprove}
          successApprove={successApprove}
          submitSmsApprove={submitSmsApprove}
          submitMobileApprove={submitMobileApprove}
        />
      )}
    </>
  );
};
