import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { selectCurrentAccount } from 'src/Redux';
import { i18, trb } from 'src/Utilities';
import { AlertNotification } from 'src/shared';

export const KYCMessages = () => {
  const { ValidTill, ID, IsUpdating } = useSelector(selectCurrentAccount) ?? {};
  const { MACR } = useSelector((state: any) => state.UACReducers);
  if (!ValidTill || (MACR?.CurrentAccountID && ID !== MACR.CurrentAccountID)) {
    return null;
  }

  const alertClassName = 'mb-2';
  const warning_message_threshold_in_days = 5270400; // 60 days in seconds

  const validTillDate = dayjs(ValidTill);
  const diffSeconds = validTillDate.diff(dayjs(), 'second');

  return (
    <div className="kyc-messages">
      {diffSeconds > 0 && diffSeconds <= warning_message_threshold_in_days && (
        <AlertNotification
          message={trb(i18.Messages.KYCIsExpiring, { date: validTillDate.format('YYYY-MM-DD') })}
          type="info"
          alertClassName={alertClassName}
          description={
            <div
              className="info_description"
              dangerouslySetInnerHTML={{ __html: trb(i18.Messages.KYCIsExpiringDescription) }}
            />
          }
        />
      )}
      {diffSeconds <= 0 && IsUpdating === true && (
        <AlertNotification
          message={trb(i18.Messages.KYCExpiredInfo, { date: validTillDate.format('YYYY-MM-DD') })}
          type="info"
          alertClassName={alertClassName}
          description={
            <div
              className="info_description"
              dangerouslySetInnerHTML={{ __html: trb(i18.Messages.KYCExpiredInfoDescription) }}
            />
          }
        />
      )}
      {diffSeconds <= 0 && IsUpdating === false && (
        <AlertNotification
          message={trb(i18.Messages.KYCExpiredError, { date: validTillDate.format('YYYY-MM-DD') })}
          type="error"
          alertClassName={alertClassName}
          description={
            <div
              className="alert_description"
              dangerouslySetInnerHTML={{ __html: trb(i18.Messages.KYCExpiredErrorDescription) }}
            />
          }
        />
      )}
    </div>
  );
};
