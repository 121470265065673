import { useState } from 'react';
import { Typography } from 'antd';
import DOMPurify from 'dompurify';

import { AlertNotification } from 'src/shared';
import { i18, trb } from 'src/Utilities';
import { ILoginMessages } from './interfaces';

export const LoginMessage = ({ Message, MsgType }: ILoginMessages) => {
  const { Text } = Typography;
  const [isExpanded, setIsExpanded] = useState(false);

  const cleanedInput = Message.replace(/&nbsp;/g, ' ');
  const cleanHTML = DOMPurify.sanitize(cleanedInput);

  return (
    <div className="mb-3">
      <AlertNotification
        description={
          <div>
            <div
              className={isExpanded ? 'text-justify' : 'text-justify truncate-two-lines'}
              dangerouslySetInnerHTML={{ __html: cleanHTML }}
            />
            <Text
              className={MsgType === 1 ? 'info_message_button' : 'error_message_button'}
              onClick={() => setIsExpanded((prevState) => !prevState)}
            >
              {trb(i18.Labels[isExpanded ? 'ShowLess' : 'ShowMore'])}
            </Text>
          </div>
        }
        alertClassName={'mb-2 p-3'}
        type={MsgType === 1 ? 'info' : 'error'}
      />
    </div>
  );
};
