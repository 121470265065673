import React, { useEffect, useRef, useState } from 'react';
import { Container, ContentLoader } from 'src/Components';
import { Steps } from 'antd';
import { PaymentFill, PaymentPreview } from 'src/Containers';
import { useDispatch, useSelector } from 'react-redux';
import { CustomIcon, i18, Payment, trb } from 'src/Utilities';
import { AMCActions, AMCConstants, PMCActions } from 'src/Redux';
import moment from 'moment/moment';
import { AlertNotification } from 'src/shared';
import { PaymentError } from 'src/Containers/Payments/components';

export const PaymentForex = () => {
  const dispatch = useDispatch();
  const { PTR, forexPayments, forexPaymentsSend } = useSelector((state) => state.PMCReducers);
  const { language } = useSelector((state) => state.languageReducer);
  const amcReducers = useSelector((state) => state.AMCReducers);
  const { forexRate, forexRateRequestData, forexDraftInfo, forexDraftErrors } = amcReducers;

  const [submitting, setSubmitting] = useState(false);
  const [checkForexSubmit, setCheckForexSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState(null);
  const [totalTime, setTotalTime] = useState(0);
  const [passedTime, setPassedTime] = useState(undefined);
  const [previewInfoData, setPreviewInfoData] = useState({ type: '', message: '' });

  const timerRef = useRef();

  useEffect(() => {
    dispatch(AMCActions.clearForexRate(language));
    dispatch(AMCActions.getForexCurrency(language));
    dispatch(PMCActions.getForexPayments());

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (forexRate?.IsValid) {
      const duration = moment.duration(moment(forexRate?.ExpiresAt).diff(moment(new Date())))._milliseconds / 1000;

      setPassedTime(0);
      setTotalTime(duration);
    }
  }, [forexRate]);

  useEffect(() => {
    const duration = moment.duration(moment(forexDraftInfo?.Calculation?.ExpiresAt).diff(moment(new Date())))._milliseconds / 1000;

    setPassedTime(0);
    setTotalTime(duration);
  }, [forexDraftInfo]);

  useEffect(() => {
    let intervalId = null;

    if (totalTime > 0 && !checkForexSubmit) {
      intervalId = setInterval(() => {
        setPassedTime((prevCount) => prevCount + 1);
      }, 1000);

      if (totalTime <= passedTime) {
        clearInterval(intervalId);

        if (currentStep === 0) {
          getForexRate();
        }
      }
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [totalTime, passedTime]);

  const getForexRate = async () => {
    await dispatch(
      AMCActions.getForexRate({
        from: forexRateRequestData?.from,
        to: forexRateRequestData?.to,
        amountFrom: forexRateRequestData?.amountFrom,
        amountTo: forexRateRequestData?.amountTo,
      })
    );
  };

  const handleForexExchange = async (values) => {
    const data = {
      from: forexRateRequestData.from,
      to: forexRateRequestData.to,
      accountFromId: values.Payment.FromAccount,
      accountToId: values.Payment.BeneficiaryAccountNumber,
    };

    if (forexRateRequestData.amountFrom) {
      data.amountFrom = forexRateRequestData.amountFrom;
    } else {
      data.amountTo = forexRateRequestData.amountTo;
    }

    const response = await dispatch(AMCActions.postForexDraft(data));
    setSubmitting(false);

    if (response?.payload?.Id) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const edit = () => {
    setTotalTime(null);
    setCurrentStep(0);
  };

  const checkSubmitStatus = async (id) => {
    const response = await dispatch(AMCActions.getForexStatus(id));
    const { error, type } = response;
    if (error?.status === 204 || error?.code === 'ERR_NETWORK') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      timerRef.current = setTimeout(() => checkSubmitStatus(id), 1000);
    } else if (type === AMCConstants.GET_FOREX_STATUS_SUCCESS) {
      setCheckForexSubmit(false);
      setSubmitting(false);
      setCurrentStep((prevStep) => prevStep + 1);

      setPreviewInfoData({ type: 'success', message: i18.Messages.ForexExchangeIsSuccessfull });
    } else {
      setCheckForexSubmit(false);
      setSubmitting(false);
      setPreviewInfoData({ type: 'error', message: error.response.data.Message });

      await resetPayment();
    }
  };

  const handleForexSubmitPreview = async () => {
    const response = await dispatch(AMCActions.confirmForexRate(forexDraftInfo.Id));
    if (response?.payload?.Success) {
      setCheckForexSubmit(true);
      checkSubmitStatus(forexDraftInfo.Id);
    } else {
      setPreviewInfoData({ type: 'error', message: response?.payload?.Reason });

      await resetPayment();
    }
  };

  const submit = async (values) => {
    setSubmitting(true);
    setData(values);

    await handleForexExchange(values);
  };

  const submitPreview = async () => {
    setSubmitting(true);

    await handleForexSubmitPreview();
  };

  const successApprove = async () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const getFormatedForexDrafrInfo = () => {
    const dt = { ...forexDraftInfo };
    if (dt.accountFromId) {
      dt.IBANAccountFrom = forexPayments?.InitialAccounts?.find((el) => el.AccountID === dt.accountFromId)?.AccountNumber;
    }
    if (dt.accountToId) {
      dt.IBANAccountTo = forexPayments?.DestinationAccounts?.find((el) => el.AccountID === dt.accountToId)?.AccountNumber;
    }

    return dt;
  };

  const resetPayment = async () => {
    dispatch(PMCActions.postPTR());
    dispatch(AMCActions.clearForexRate(language));
    setTotalTime(null);
    setPassedTime(null);
    setData({
      Payment: {},
    });
    setPreviewInfoData({ type: '', message: '' });
    setSubmitting(false);
    setCurrentStep(0);
  };

  const FillError = () => {
    const errors = forexDraftErrors?.response?.data?.errorCode
      ? [{ ErrorCode: forexDraftErrors?.response?.data?.errorCode }]
      : forexRate?.IsValid === false
        ? [{ ErrorCode: i18.ErrorDescriptions.ForexExchangeIsNotValid }]
        : null;

    return errors ? <PaymentError errors={errors} /> : null;
  };

  return (
    <>
      <div className="eb_content_subtitle">{trb(i18.ContentTitles.ForexExchange)}</div>
      <Container wide>
        <Steps
          direction="horizontal"
          current={currentStep}
          className={`mb-3 mb-md-2 eb_steps`}
        >
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentFirstStep"
              />
            }
          />
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentSecondStep"
              />
            }
          />
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentThirdStep"
              />
            }
          />
        </Steps>
        <div className="position-relative w-100">
          {currentStep === 0 &&
            (forexPaymentsSend ? (
              <ContentLoader />
            ) : (
              <PaymentFill
                PTR={PTR}
                paymentType={Payment.FOREX_EXCHANGE}
                data={data}
                forexPayments={forexPayments}
                submit={submitting ? () => {} : submit}
                submitting={submitting}
                submitDisabled={forexRate?.IsValid === false}
                infoData={
                  <>
                    <AlertNotification
                      message={trb(i18.Messages.ForexExchangeFillInfoMessage)}
                      type="info"
                      alertClassName="py-3 mb-3"
                    />
                    <FillError />
                  </>
                }
                setTotalTime={setTotalTime}
                forexTimerValues={{
                  forexTimerVisible: forexRate?.IsValid && !forexDraftErrors?.response?.data?.errorCode && !checkForexSubmit,
                  passedTime: passedTime,
                  totalTime: totalTime,
                  setTotalTime: setTotalTime,
                }}
              />
            ))}
          {currentStep === 1 && (
            <PaymentPreview
              paymentType={Payment.FOREX_EXCHANGE}
              data={data}
              PTR={PTR}
              edit={edit}
              submit={submitting ? () => {} : submitPreview}
              submitting={submitting}
              successApprove={successApprove}
              forexDraftInfo={getFormatedForexDrafrInfo()}
              checkForexSubmit={checkForexSubmit}
              infoData={
                <AlertNotification
                  message={trb(i18.Messages.ForexExchangePreviewInfoMessage)}
                  type="info"
                  alertClassName="py-3 mb-3"
                />
              }
              forexTimerValues={{
                forexTimerVisible: forexRate?.IsValid && !forexDraftErrors?.response?.data?.errorCode && !checkForexSubmit,
                passedTime: passedTime,
                totalTime: totalTime,
              }}
            />
          )}
          {currentStep === 2 && (
            <PaymentPreview
              paymentType={Payment.FOREX_EXCHANGE}
              data={data}
              PTR={PTR}
              edit={edit}
              submit={submitting ? () => {} : submitPreview}
              submitting={submitting}
              success
              forexDraftInfo={getFormatedForexDrafrInfo()}
              resetPayment={resetPayment}
              infoData={
                previewInfoData.type ? (
                  <AlertNotification
                    message={trb(previewInfoData.message)}
                    type={previewInfoData.type}
                    alertClassName="py-3 mb-3"
                  />
                ) : null
              }
            />
          )}
          {checkForexSubmit && <ContentLoader title={trb(i18.Labels.CheckForexStatus)} />}
        </div>
      </Container>
    </>
  );
};
