import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Flex, Modal, Result } from 'antd';

import { ContentLoader, ContentNoData, Notification, Number, OperationApproval } from 'src/Components';
import { CURRENCIES, SMS_TOKEN_TYPES, SIGN_CANCEL_TYPES, currencyTextValue, i18, numberFormat, translationGroups, trb } from 'src/Utilities';
import { OMCActions, OMCConstants, PMCActions } from 'src/Redux';
import moment from 'moment/moment';
import { PaymentError } from '../../components';
import { AlertNotification } from 'src/shared';

const maxCheckedRow = 30;
const messagesEndRef = React.createRef();

export const UnsignedPayments = () => {
  const dispatch = useDispatch();
  const { UOLR, sendUOLR, PSUOR, sendPSUOR, sendSUORSMS } = useSelector((state) => state.OMCReducers);
  const { sendPTR, SDPVR, sendSDPVR } = useSelector((state) => state.PMCReducers);
  const { sendATR } = useSelector((state) => state.UACReducers);
  const { language } = useSelector((state) => state.languageReducer);
  const [state, setState] = useState({
    selectedRowKeys: [],
    send: false,
    modalVisible: null,
    itemInst: null,
    isError: false,
    isErrorGlobal: false,
    errors: [],
    currentStep: 0,
  });

  const loading = sendUOLR || sendATR || sendPTR || state.send;

  const loadData = () => {
    dispatch(OMCActions.postUOLR());
  };

  useEffect(() => {
    loadData();
  }, []);

  const selectRow = (checked, id) => {
    if (checked) {
      setState({
        ...state,
        selectedRowKeys: [...state.selectedRowKeys, id],
      });
    } else {
      setState({
        ...state,
        selectedRowKeys: state.selectedRowKeys.filter((e) => e !== id),
      });
    }
  };

  const clickDeleteButton = () => {
    const { selectedRowKeys } = state;

    dispatch(OMCActions.postDUOR({ ItemsIDs: selectedRowKeys })).then((response) => {
      if (response.type === OMCConstants.POST_OMC_DUOR_SUCCESS) {
        if (response.payload.IsSuccessfull) {
          Notification({
            type: 'success',
            message: trb(i18.MessageTitles.DeletedSuccessUnsignedPaymentsTitle),
            description: trb(i18.Messages.DeletedSuccessUnsignedPaymentsDesc),
          });
          dispatch(OMCActions.postUOLR());
        } else {
          Notification({
            type: 'error',
            message: trb(i18.Messages.ResponseError),
          });
        }
      }
      setState({ ...state, selectedRowKeys: [], errors: [], isError: false });
    });
  };

  const clickApproveButton = (itemInst = null) => {
    const selectedRowKeys = itemInst ? [itemInst.ID] : state.selectedRowKeys,
      { Operations } = UOLR;

    setState({
      ...state,
      send: true,
      itemInst,
      approve: false,
      approveInst: false,
      errors: null,
    });

    const checkFeeData = {
      GroupPayments: Operations.filter((item) => selectedRowKeys.includes(item.ID)).map((item) => ({
        ID: String(item.ID),
        Description: item.Description,
        Amount: item.Amount[0].Value,
      })),
    };

    dispatch(OMCActions.resetPSUOR());
    dispatch(PMCActions.postSDPVR(checkFeeData));
  };

  useEffect(() => {
    if (SDPVR?.IsSuccessfull) {
      setState({
        ...state,
        modalVisible: true,
        send: false,
        approve: false,
        approveInst: false,
        isError: false,
        errors: null,
        // selectedRowKeys: [],
      });
    } else if (SDPVR?.IsSuccessfull === false) {
      setState({
        ...state,
        approve: false,
        approveInst: false,
        RQ_ID: null,
        AuthType: null,
        Code: null,
        send: false,
        submitting: false,
        selectedRowKeys: [],
      });
      showErrors(SDPVR);
    }
  }, [SDPVR]);

  useEffect(() => {
    let newState = { ...state, send: false };

    if (PSUOR?.IsSuccessfull) {
      if (PSUOR?.ErrorMessage) {
        showErrors(PSUOR);
        // TODO - check if this is needed
        // loadData();
        return;
      }

      const rq = PSUOR?.AuthType && SMS_TOKEN_TYPES.includes(PSUOR?.AuthType.toUpperCase()) ? PSUOR?.SMSToken : PSUOR?.iSignToken;

      newState = state.itemInst
        ? {
            ...state,
            approveInst: state.itemInst.ID,
            AuthType: PSUOR?.AuthType,
            RQ_ID: rq,
            Code: PSUOR?.ControlCode,
            modalVisible: true,
          }
        : {
            ...state,
            approve: true,
            AuthType: PSUOR?.AuthType,
            RQ_ID: rq,
            Code: PSUOR?.ControlCode,
            modalVisible: true,
          };
    } else if (PSUOR?.IsSuccessfull === false) {
      showErrors(PSUOR);

      return;
    }

    setState(newState);
  }, [PSUOR]);

  const showErrors = (response) => {
    setState((prev) => ({
      ...prev,
      modalVisible: false,
      isError: true,
      send: false,
      errors: {
        errorList: response?.PaymentErrorsItems?.filter((items, index) => index === response.PaymentErrorsItems.findIndex((item) => item.ErrorCode === items.ErrorCode)) || [
          { ErrorCode: response?.ErrorCode || 'Unknown' },
        ],
      },
    }));

    loadData();
  };

  const cancelApprove = (type, errorCode, notificationType = 'warning') => {
    let message;

    switch (type) {
      case SIGN_CANCEL_TYPES.TIMEOUT:
        message = 'PaymentCanceledTimeout';
        break;
      case SIGN_CANCEL_TYPES.MANUAL:
        message = 'PaymentCanceledManual';
        break;
      case SIGN_CANCEL_TYPES.REJECTED:
        message = 'PaymentCanceledRejected';
        break;
      default:
        message = null;
        break;
    }
    if (message) {
      Notification({
        type: notificationType,
        message: i18.NotificationTitles[message],
        description: i18.NotificationMessages[message],
      });
    }

    setState({
      ...state,
      approve: false,
      approveInst: false,
      RQ_ID: null,
      AuthType: null,
      Code: null,
      send: false,
      submitting: false,
      isErrorGlobal: !message,
    });
  };

  const clickApproveModal = async () => {
    const { itemInst } = state;
    const selectedRowKeys = itemInst ? [itemInst.ID] : state.selectedRowKeys;

    setState({
      ...state,
      send: true,
    });

    const res = await dispatch(OMCActions.postPSUOR({ ItemsIDs: selectedRowKeys, Language: language }));

    if (!res.payload?.IsSuccessfull) {
      setState({
        ...state,
        send: false,
      });

      cancelApprove(SIGN_CANCEL_TYPES.REJECTED, null, 'warning');
    }
  };

  const successApprove = () => {
    const { itemInst } = state;
    const selectedRowKeys = itemInst ? [itemInst.ID] : state.selectedRowKeys;

    dispatch(OMCActions.removeUOLRCompleted(selectedRowKeys));

    setState({
      ...state,
      approve: false,
      approveInst: false,
      send: false,
      currentStep: state.currentStep + 1,
      selectedRowKeys: [],
      modalVisible: false,
    });

    Notification({
      type: 'success',
      message: i18.NotificationTitles.SuccessApprove,
      description: i18.NotificationMessages.SuccessApprove,
    });
  };

  const submitSmsApprove = (values) => {
    dispatch(OMCActions.postSUORSMS(values)).then((response) => {
      const ErrorCode = response?.payload?.ErrorCode;

      if (response.payload.IsSuccessfull) {
        successApprove();
      } else {
        cancelApprove(null, ErrorCode, 'error');
      }
    });
  };

  const submitMobileApprove = async () => {
    const { RQ_ID } = state;
    const response = await dispatch(OMCActions.postSUOR({ iSignToken: RQ_ID }));
    const AuthType = response?.payload?.AuthType;
    const IsSuccessfull = response?.payload?.IsSuccessfull;
    const ErrorCode = response?.payload?.ErrorCode;

    if (AuthType === null && IsSuccessfull === false) {
      return true;
    }

    if (AuthType === 'false') {
      cancelApprove(null, ErrorCode);
    } else if (IsSuccessfull === true) {
      successApprove();
    }
    return false;
  };

  const checkCountMore = (disabled = true) => {
    if (disabled) {
      Notification({
        type: 'error',
        message: trb(i18.Messages.NoSignedPaymentErrorMuchMore),
        description: i18.NotificationMessages.NoSignedPaymentErrorMuchMore + `: ${maxCheckedRow}`,
      });
    }
  };

  const checkRow = (e, id = null) => {
    const { selectedRowKeys } = state;

    if (selectedRowKeys.length === maxCheckedRow - 1) checkCountMore();
    selectRow(e.target.checked, id);
  };

  const checkAllRow = (e) => {
    const selectedRowKeys = [];
    UOLR.Operations.slice(0, maxCheckedRow).forEach((item) => {
      if (e.target.checked) {
        selectedRowKeys.push(item.ID);
      }
    });
    setState({ ...state, selectedRowKeys });
  };

  const { modalVisible } = state,
    PaymentsTotalAmount = SDPVR?.PaymentsTotalAmount,
    PaymentsTotalCurrency = SDPVR?.GroupPayments?.[0]?.currency,
    PaymentsCount = SDPVR?.PaymentsCount;

  if (sendUOLR) {
    return <ContentLoader />;
  }

  if (!UOLR) {
    return <ContentNoData id="UnsignedPaymentsUOLR" />;
  }

  const { selectedRowKeys, isError, errors } = state;

  return (
    <>
      {modalVisible && (
        <Modal
          width={700}
          centered
          style={{ top: 0 }}
          styles={{
            body: {
              padding: 0,
              paddingBottom: 0,
            },
          }}
          open={modalVisible}
          title={<div className="eb_fw_600">{trb(state.itemInst ? i18.Labels.PaymentSubmitModalInstant : i18.Labels.UnsignedPaymentSubmitModal)}</div>}
          onCancel={() => {
            if (sendPSUOR || sendSUORSMS) {
              return;
            }

            if (state.isErrorGlobal) {
              loadData();
            }

            setState({ ...state, modalVisible: false, itemInst: null, send: false, isErrorGlobal: false });
          }}
          closable={true}
          footer={[
            <Button.Group key="footer">
              {!state.isErrorGlobal && (
                <Button
                  id="UnsignedPaymentsSubmitButton"
                  type="primary"
                  disabled={sendPSUOR || loading || state.approve || state.approveInst}
                  onClick={clickApproveModal}
                >
                  {trb(i18.Buttons.Approve)}
                </Button>
              )}
              <Button
                id="UnsignedPaymentsCancelButton"
                type="default"
                disabled={sendPSUOR || loading || state.approve || state.approveInst}
                style={{ marginLeft: 0 }}
                onClick={() => {
                  if (sendPSUOR || sendSUORSMS) {
                    return;
                  }

                  if (state.isErrorGlobal) {
                    loadData();
                  }

                  setState({
                    ...state,
                    modalVisible: false,
                    itemInst: null,
                    approve: false,
                    approveInst: false,
                    RQ_ID: null,
                    AuthType: null,
                    Code: null,
                    send: false,
                    submitting: false,
                    isErrorGlobal: false,
                  });
                }}
              >
                {state.isErrorGlobal ? trb(i18.Buttons.Close) : trb(i18.Buttons.Cancel)}
              </Button>
            </Button.Group>,
          ]}
        >
          {state.isErrorGlobal ? (
            <div className="px-0 pb-3 p-md-3">
              <Result
                status="error"
                title={trb(i18.ErrorTitles.Unknown)}
                subTitle={trb(i18.ErrorDescriptions.Unknown)}
              />
            </div>
          ) : (
            <div className="px-0 pb-3 p-md-3">
              {SDPVR?.Report?.map(({ Amount, Commission, CommissionCurrency, Currency, Quantity, ExecutionDate, CutOffTime }, index) => (
                <div
                  className="row"
                  key={index}
                >
                  <div className="col-8 col-md-3 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">{trb(i18.Labels.PaymentsCount)}</div>
                  <div className="col-4 col-md-1 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-10">
                    <Number
                      value={Quantity}
                      format={numberFormat().number}
                    />
                  </div>
                  <div className="col-12 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                    {trb(i18.Labels.PaymentsTotalAmount)}
                    <span className="ext-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                      <Number value={Amount} />
                      {` ${Currency}`}
                    </span>
                  </div>
                  <div className="col-12 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                    {trb(i18.Labels.PaymentsTotalCommFee)}
                    <span className="py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                      <Number value={Commission} />
                      {` ${CommissionCurrency}`}
                    </span>
                  </div>
                  <div className="col-12 col-md-4 py-1 text-black-50 my-auto">
                    {ExecutionDate ? `${trb(i18.Labels.ExecutionDateValue, { executionDate: moment(ExecutionDate).format('YYYY-MM-DD') })}` : ''}
                  </div>
                  <div className="col-12 col-md-5 py-1 text-black-50 my-auto">{CutOffTime ? `${trb(i18.Labels.ExecutionDateCutOff, { sameDayCutOff: CutOffTime })}` : ''}</div>
                </div>
              ))}
              {sendPSUOR ? <ContentLoader /> : null}
            </div>
          )}
          {state.approve && (
            <div className="pb-3">
              <OperationApproval
                RQ_ID={state.RQ_ID}
                AuthType={state.AuthType}
                code={state.Code}
                cancelApprove={cancelApprove}
                successApprove={successApprove}
                submitSmsApprove={submitSmsApprove}
                submitMobileApprove={submitMobileApprove}
                approveCount={PaymentsCount}
                loading={sendPSUOR || sendSUORSMS}
              />
            </div>
          )}
          {state.approveInst && (
            <div className="pb-3">
              <OperationApproval
                RQ_ID={state.RQ_ID}
                AuthType={state.AuthType}
                code={state.Code}
                cancelApprove={cancelApprove}
                successApprove={successApprove}
                submitSmsApprove={submitSmsApprove}
                submitMobileApprove={submitMobileApprove}
                approveSum={{
                  sum: PaymentsTotalAmount,
                  currency: PaymentsTotalCurrency,
                }}
                loading={sendPSUOR || sendSUORSMS}
              />
            </div>
          )}
        </Modal>
      )}
      {isError &&
        (SDPVR?.ErrorCaptions.length ? (
          <PaymentError errors={SDPVR?.ErrorCaptions} />
        ) : (
          <div className="mb-4">
            <AlertNotification
              className="w-100"
              message=""
              description={errors?.errorList?.map((item, index) => (
                <div key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</div>
              ))}
              type="info"
            />
          </div>
        ))}
      {!UOLR?.Operations?.length && (
        <div className="mb-2 w-100">
          <ContentNoData id="UnsignedPaymentsOperations" />
        </div>
      )}
      {!!UOLR?.Operations?.length && (
        <>
          <div className="mb-2 w-100">
            <div className="eb_table_wrapper eb_table_nosigned_payment eb_table_wrapper_bottom_lined mb-2">
              <div
                id="UnsignedPaymentsTable"
                className="eb_table"
              >
                <div className="row eb_table_head d-flex d-md-flex">
                  <div className="col-12 col-md-2 px-0 px-md-3 py-1 py-md-2 text-nowrap d-none d-md-block">
                    <Checkbox
                      id="UnsignedPaymentsAllCheckboxLG"
                      className="mr-2"
                      disabled={loading}
                      onChange={checkAllRow}
                    />
                    {trb(i18.Labels.Date)}
                  </div>
                  <div className="col-1 text-nowrap px-0 d-block d-md-none">
                    <Checkbox
                      id="UnsignedPaymentsAllCheckboxMD"
                      className="mr-2"
                      disabled={loading}
                      onChange={checkAllRow}
                    />
                    {trb(i18.Labels.SelectAll)}
                  </div>
                  <div className="col-3 d-none d-md-block">{trb(i18.Labels.SenderData)}</div>
                  <div className="col-3 d-none d-md-block">{trb(i18.Labels.ReceivedData)}</div>
                  <div className="col-2 d-none d-md-block">{trb(i18.Labels.PaymentDescription)}</div>
                  <div className="col-2 text-right d-none d-md-block">{trb(i18.Labels.Amount)}</div>
                </div>
                {UOLR.Operations.map((item, index) => {
                  const disabled = selectedRowKeys.length === maxCheckedRow && !selectedRowKeys.includes(item.ID);
                  return (
                    <React.Fragment key={index}>
                      <div
                        id={`UnsignedPaymentsTR${index}`}
                        className="row eb_table_row text-word-wrap py-1 py-md-0 overflow-hidden"
                      >
                        <div
                          className="col-12 col-md-2 px-0 px-md-3 py-1 py-md-2 text-nowrap"
                          onClick={() => checkCountMore(disabled)}
                        >
                          <div className="row">
                            <div className="d-none d-md-block col-1 col-md-12 mb-2">
                              <Checkbox
                                id={`UnsignedPaymentsTR${index}CheckboxLG`}
                                checked={selectedRowKeys.includes(item.ID)}
                                className="mr-2"
                                disabled={loading || disabled}
                                onChange={(e) => checkRow(e, item.ID)}
                              />
                              {item.DateFormatted}
                            </div>
                            <div
                              style={{ position: 'relative', left: 15 }}
                              className="d-block d-md-none col-1 col-md-12 mb-2 px-0"
                            >
                              <Checkbox
                                id={`UnsignedPaymentsTR${index}CheckboxMD`}
                                checked={selectedRowKeys.includes(item.ID)}
                                disabled={loading || disabled}
                                onChange={(e) => checkRow(e, item.ID)}
                              />
                            </div>
                            <div className="col-4 mb-2 text-black-50 d-md-none">{trb(i18.Labels.Date)}</div>
                            <div className="col-7 d-md-none mb-2">
                              <span className="eb_fw_300 unassigned-payments-right-value">{item.DateFormatted}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 px-0 px-md-3 py-1 py-md-2">
                          <div className="row">
                            <div className="col-1 mb-2 d-md-none px-0" />
                            <div className="col-11 mb-2 d-block d-md-none text-black-50">{trb(i18.Labels.SenderData)}</div>
                            <div className="col-1 mb-2 d-md-none px-0" />
                            <div className="col-11 col-md-12 mb-2">
                              <div>{item.SenderName}</div>
                              <div className="eb_fw_300">{item.SenderAccount}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 px-0 px-md-3 py-1 py-md-2">
                          <div className="row">
                            <div className="col-1 mb-2 d-md-none px-0" />
                            <div className="col-11 mb-2 d-block d-md-none text-black-50">{trb(i18.Labels.ReceivedData)}</div>
                            <div className="col-1 mb-2 d-md-none px-0" />
                            <div className="col-11 col-md-12 mb-2">
                              <div>{item.ReceiverName}</div>
                              <div className="eb_fw_300">{item.ReceiverAccount}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-2 px-0 px-md-3 py-1 py-md-2">
                          <div className="row">
                            <div className="col-1 mb-2 d-md-none px-0" />
                            <div className="col-11 mb-2 d-block d-md-none text-black-50">{trb(i18.Labels.PaymentDescription)}</div>
                            <div className="col-1 mb-2 d-md-none px-0" />
                            <div className="col-11 col-md-12 mb-2">
                              <div>{item.Description}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-2 px-0 px-md-3 py-1 py-md-2 text-right">
                          <div className="row text-start text-md-end">
                            <div className="col-1 mb-2 d-md-none px-0" />
                            <div className="col-4 mb-2 d-block d-md-none text-black-50">{trb(i18.Labels.Amount)}</div>
                            <div className="col-7 col-md-12 text-nowrap text-primary eb_fw_600">
                              <div className="unassigned-payments-right-value">{currencyTextValue(item.Amount[0].Value, item.Amount[0].Currency || CURRENCIES.EUR)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item.PaymentSystem === 'INST' && UOLR.Operations.length !== selectedRowKeys.length && !selectedRowKeys.includes(item.ID) && (
                        <div className="text-end mb-4">
                          <Button
                            id={`UnsignedPaymentsTR${index}ApproveINSTPaymentButton`}
                            disabled={sendSDPVR}
                            onClick={() => {
                              setState((prevState) => ({
                                ...prevState,
                                itemInst: item,
                              }));

                              clickApproveButton(item);
                            }}
                          >
                            {trb(i18.Buttons.ApproveINSTPayment)}
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>

          <Flex
            ref={messagesEndRef}
            justify="end"
            gap="small"
            wrap="wrap"
            className="mb-2"
          >
            <Button
              id="UnsignedPaymentsDeleteButton"
              onClick={clickDeleteButton}
              disabled={selectedRowKeys.length <= 0 || modalVisible || loading}
              className="col-12 col-md-auto"
              size="large"
            >
              {trb(i18.Buttons.DeleteSelectedPayments)}
            </Button>
            <Button
              id="UnsignedPaymentsApproveButton"
              onClick={() => clickApproveButton()} //Don't delete () => () check clickApproveButton action
              disabled={selectedRowKeys.length <= 0 || modalVisible || loading}
              className="col-12 col-md-auto"
              size="large"
              type={selectedRowKeys.length ? 'primary' : 'default'}
            >
              {trb(i18.Buttons.SignSelectedPayments)}
            </Button>
          </Flex>
          {loading && !state.approve && !state.approveInst && !modalVisible && <ContentLoader style={{ backgroundColor: 'white', paddingTop: 30 }} />}
        </>
      )}
    </>
  );
};
