import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { i18, trb } from 'src/Utilities';
import { ContentMenuMobile } from 'src/Components';
import { KYCMessages } from './components';

export const PartContent = ({ children, options }) => {
  const contentRef = React.createRef();

  const { contentTitle, menuCollapsed } = useSelector((state) => state.MenuReducer);

  const updateDimensions = () => {
    const termNode = document.getElementsByClassName('form-field-terms')[0];
    const otherNode = document.getElementsByClassName('form-field-wrapper')[0];

    if (termNode && otherNode && contentRef.current) {
      const marginSize = ((contentRef.current.clientWidth - otherNode.clientWidth) / 2) * -1;
      termNode.style.marginRight = `${marginSize}px`;
      termNode.style.marginLeft = `${marginSize}px`;
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateDimensions();
    }, 10);
    setTimeout(() => clearInterval(interval), 300);
  }, [menuCollapsed]);

  return (
    <div
      className="eb_main"
      id="eb_main"
    >
      <div className="eb_inner d-flex">
        <div className="eb_content_wrapper w-100">
          <div
            className="eb_content"
            ref={contentRef}
          >
            {contentTitle && <ContentMenuMobile />}
            {options?.isKYCMessages && <KYCMessages />}
            <div>{children}</div>
            <div className="text-gray-basic eb_fw_300 mt-5 footer-copyright">{trb(i18.Labels.FooterCopyright, { yyyy: new Date().getFullYear() })}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
