import { yup } from 'src/utils';
import { REGEX } from '../../../constants';
import { i18 } from '../../../Utilities';

export const validationPaymentInternational = yup.object().shape({
  Payment: yup.object().shape({
    Template: yup.string().nullable(),
    InitialAccountID: yup
      .string()
      .nullable()
      .when('PaymentFormType', {
        is: (val: string | undefined) => val !== 'template',
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    OverdraftValue: yup.number().nullable(),
    Recipient: yup
      .string()
      .nullable()
      .required()
      .max(70)
      .trim()
      .matches(REGEX.INTERNATIONAL, { message: { error: i18.PaymensValidations.InvalidNameError } }),
    BeneficiaryAccountNumber: yup
      .string()
      .nullable()
      .required()
      .trim()
      .min(1)
      .matches(REGEX.INTERNATIONAL, { message: { error: i18.PaymensValidations.InvalidNameError } }),
    Amount: yup
      .number()
      .nullable()
      .when('PaymentFormType', {
        is: (val: string | undefined) => val !== 'template',
        then: () =>
          yup
            .number()
            .nullable()
            .required()
            .min(0.01)
            .test('amountAvailable', { error: i18.Validations.InsufficientFunds }, function (value) {
              const amountAvailable = this.parent.AmountAvailable;

              return !(value && (amountAvailable || amountAvailable === 0) && value > amountAvailable);
            }),
        otherwise: () => yup.string().nullable(),
      }),

    AmountAvailable: yup.number().nullable(),
    Currency: yup
      .string()
      .nullable()
      .when('PaymentFormType', {
        is: (val: string | undefined) => val !== 'template',
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    Purpose: yup
      .string()
      .nullable()
      .required()
      .min(1)
      .max(140, { error: 'Validations.MaxPurposeInput', options: { max: 140 } })
      .trim()
      .matches(REGEX.INTERNATIONAL, { message: { error: i18.PaymensValidations.InvalidNameError } }),
    BeneficiaryAddress: yup
      .string()
      .nullable()
      .required()
      .max(70)
      .trim()
      .matches(REGEX.INTERNATIONAL, { message: { error: i18.PaymensValidations.InvalidNameError } }),
    RecipientBankCountry: yup
      .string()
      .nullable()
      .required()
      .max(35)
      .trim()
      .matches(REGEX.INTERNATIONAL, { message: { error: i18.PaymensValidations.InvalidNameError } }),
    BeneficiaryCity: yup
      .string()
      .nullable()
      .required()
      .max(35)
      .trim()
      .matches(REGEX.INTERNATIONAL, { message: { error: i18.PaymensValidations.InvalidNameError } }),
    BeneficiaryPostCode: yup
      .string()
      .nullable()
      .required()
      .max(35)
      .trim()
      .matches(REGEX.INTERNATIONAL, { message: { error: i18.PaymensValidations.InvalidNameError } }),
    CorrespondentBankBic: yup
      .string()
      .nullable()
      .required()
      .matches(REGEX.CHAR_NUMBER_NO_SPACE, { message: { error: i18.PaymensValidations.InvalidBICCodeError } })
      .min(8)
      .max(11),
    SaveTemplate: yup.boolean().nullable(),
    TemplateName: yup
      .string()
      .nullable()
      .when(['SaveTemplate', 'PaymentFormType'], {
        is: (SaveTemplate: boolean, PaymentFormType: string) => SaveTemplate || PaymentFormType === 'template',
        then: () =>
          yup
            .string()
            .nullable()
            .required()
            .trim()
            .matches(REGEX.INTERNATIONAL, {
              message: { error: i18.PaymensValidations.InvalidNameError },
            })
            .max(70),
        otherwise: () => yup.string(),
      }),
  }),
});
